<template>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-invoice-2">
                    <div class="kt-invoice__head">
                        <div class="kt-invoice__container">
                            <div class="kt-invoice__brand">
                <span class="kt-invoice__title">INVOICE <br>
                <span class="kt-invoice__desc" v-if="data.request_form =='NP'">
                    <span>TAX NO. {{data.country_config.tax_no}}</span>
                 </span>
                </span>

                                <div href="#" class="kt-invoice__logo">
                                    <a href="#">
                                        <img src="/assets/media/logos/yelko_logo_black.png"/>
                                    </a>
                                    <span class="kt-invoice__desc">
                    <span v-if="data.request_form !='NP'">Yelko Web Pty. Ltd.</span>
                    <span v-else>Yelko Web Pvt. Ltd.</span>
                    <span v-if="data.country_config">{{data.country_config.email}}</span>
                    <span v-if="data.country_config">{{data.country_config.phone +'/'+data.country_config.contact}}</span>
                    <span v-if="data.country_config">{{data.country_config.address}}</span>
                  </span>
                                </div>
                            </div>
                            <div class="kt-invoice__items">
                                <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle">DATE</span>
                                    <span class="kt-invoice__text">{{data.created_at}}</span>
                                </div>
                                <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle">PAID AT</span>
                                    <span class="kt-invoice__text">{{data.order_paid_time}}</span>
                                </div>
                                <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle">INVOICE NO.</span>
                                    <span class="kt-invoice__text"> {{data.invoice_no}}</span>
                                </div>
                                <div class="kt-invoice__item">
                                    <span class="kt-invoice__subtitle">INVOICE TO.</span>
                                    <span class="kt-invoice__text">
                                            <p>
                                              {{data.invoiced_to}}

                                            </p>
                                             <p>
                                              {{ data.invoice_address }}
                                             </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-invoice__body">
                        <div class="kt-invoice__container">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>DESCRIPTION</th>
                                        <th>Terms</th>
                                        <th>RATE</th>
                                        <th>AMOUNT</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(invoice,index) in data.items" :key="index">
                                        <td v-text="invoice.title"></td>
                                        <td v-text="invoice.term+' month'"></td>
                                        <td v-if="data.currency">{{ data.currency }} {{ invoice.unit_price}}</td>
                                        <td v-if="data.currency"
                                                class="kt-font-primary kt-font-lg"
                                        >{{ data.currency }} {{invoice.item_total}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sub Total</td>
                                        <td></td>
                                        <td></td>
                                        <td v-if="data.currency"> {{ data.currency }} {{ data.sub_total }}</td>
                                    </tr>
                                    <tr v-if="data.country_config && data.country_config.taxable">
                                        <td> TAX / VAT</td>
                                        <td></td>
                                        <td></td>
                                        <td v-if="data.currency"> {{ data.currency }} {{ data.tax_total }}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td v-if="data.currency"> {{ data.currency }} {{ data.invoice_total }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="kt-invoice__footer">
                        <div class="kt-invoice__container">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Payment Method</th>
                                        <th>PayerName</th>
                                        <th>Paid Date</th>
                                        <th>TOTAL AMOUNT</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td v-if="data.cart">{{data.cart.payment_method}}</td>
                                        <td v-if="data.cart">{{data.cart.payer_name}}</td>
                                        <td v-if="data.cart">{{data.cart.order_paid_time}}</td>
                                        <td class="kt-font-primary kt-font-xl kt-font-boldest" v-if="data.currency" >{{
                                            data.currency }} {{ data.invoice_total }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="kt-invoice__actions">
                        <div class="kt-invoice__container">
                            <button
                                    type="button"
                                    class="btn btn-label-brand btn-bold"
                                    onclick="window.print();"
                            >Download Invoice
                            </button>
                            <button
                                    type="button"
                                    class="btn btn-brand btn-bold"
                                    onclick="window.print();"
                            >Print Invoice
                            </button>
                            <router-link :to="{name:'billing'}" class=" ml-2 btn btn-danger btn-bold">Back</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import InvoiceService from "@/services/invoice/InvoiceService";

    const Invoice = new InvoiceService();
    export default {
        name: "invoice",
        data() {
            return {
                data: {}
            };
        },
        methods: {
            getBillings() {
                Invoice.getInvoice(this.cartId, this.invoiceId).then(response => {
                    this.data = response.data;
                });
            }
        },
        computed: {
            cartId() {
                return this.$route.params.cartId;
            },
            invoiceId() {
                return this.$route.params.invoiceId;
            }
        },
        mounted() {
            this.getBillings();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Invoice", route: "dashboard"}
            ]);
        }
    };
</script>