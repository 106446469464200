import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class InvoiceService {

      getAllByUser() {
            let url = API_URL + 'user/invoice'
            return apiService.get(url);
      }
      getInvoice(cartId, invoiceId) {
            let url = API_URL + 'user/cart/' + cartId + '/invoice/' + invoiceId
            return apiService.get(url);
      }

      search(data){
            let url = API_URL + 'user/invoice/search';
            return apiService.post(url, data);
      }
}
